import PropTypes from 'prop-types'

import useWindowSize from 'hooks/useWindowSize'

import SliderBlock from 'components/SliderBlock'
import CloudinaryMedia from 'components/CloudinaryMedia'

import s from './GallerySliderBlock.module.css'

function GallerySliderBlock({ gallery }) {
  if (gallery?.length < 1) return null

  const windowSize = useWindowSize()
  const isDesktop = windowSize?.width >= 900

  const sliderSettings = {
    arrows: isDesktop ? true : false,
    dots: true,
    centerMode: true,
    centerPadding: '20%',
  }

  return (
    <div className={s.container}>
      <SliderBlock className={s.slider} settings={sliderSettings}>
        {gallery.map(({ mediaType, image, videoMp4 }) => {
          if (image !== null || videoMp4 !== null) {
            return (
              <div key={image?.mediaItemUrl || videoMp4?.mediaItemUrl}>
                <CloudinaryMedia
                  className={s.media}
                  videos={[
                    {
                      url:
                        (mediaType === 'video' && videoMp4?.mediaItemUrl) || '',
                      type: videoMp4?.mimeType,
                      params: 'f_auto,vc_auto,ac_none',
                    },
                  ]}
                  videoLoop
                  images={[
                    {
                      url: (mediaType === 'image' && image?.mediaItemUrl) || '',
                      params: 'w_899,f_auto',
                    },
                    {
                      url: (mediaType === 'image' && image?.mediaItemUrl) || '',
                      params: 'w_1112,f_auto',
                    },
                  ]}
                  // placeholderColour={placeholderColour}
                  // noARPadding
                  // noRatio
                  // noLazy
                />
              </div>
            )
          }
        })}
      </SliderBlock>
    </div>
  )
}

GallerySliderBlock.propTypes = {
  gallery: PropTypes.array,
}
GallerySliderBlock.defaultProps = {
  gallery: [],
}

export default GallerySliderBlock
