import PropTypes from 'prop-types'

import ImageVideoBlock from './ImageVideoBlock'
import GallerySliderBlock from './GallerySliderBlock'
import GalleryStackBlock from './GalleryStackBlock'
import TextBlock from './TextBlock'
import TextSectionsBlock from './TextSectionsBlock'
import SlideshowBlock from './SlideshowBlock'
import React from 'react'

function Blocks({ postType, children, ...rest }) {
  if (!postType && !children) return null

  const imageVideoBlock = `${postType}_Acfblocks_ContentBlocks_ImageVideo`
  const galleryBlock = `${postType}_Acfblocks_ContentBlocks_Gallery`
  const textBlock = `${postType}_Acfblocks_ContentBlocks_Text`
  const textSectionsBlock = `${postType}_Acfblocks_ContentBlocks_TextSections`
  const slideshowBlock = `${postType}_Acfblocks_ContentBlocks_Slideshow`

  const blockType = (block) => {
    switch (block.__typename) {
      case imageVideoBlock:
        return {
          classPrefix: 'gallery',
          component: ImageVideoBlock,
        }
      case galleryBlock:
        return {
          classPrefix: 'gallery',
          component:
            block.galleryStyle === 'stack'
              ? GalleryStackBlock
              : GallerySliderBlock,
        }
      case textBlock:
        return {
          classPrefix: 'text',
          component: TextBlock,
        }
      case textSectionsBlock:
        return {
          classPrefix: 'sections',
          component: TextSectionsBlock,
        }
      case slideshowBlock:
        return {
          classPrefix: 'slideshow',
          component: SlideshowBlock,
        }
      default:
        return null
    }
  }

  return children.contentBlocks.map((child, i) => {
    // console.log('child: ', child)
    if (!child) return null
    const getBlockType = blockType(child)
    if (!getBlockType) return null
    const component = React.createElement(getBlockType.component, {
      ...child,
      key: i,
      ...rest,
    })
    return (
      <div
        key={i}
        className={`${getBlockType.classPrefix}Block
        `}
      >
        {component}
      </div>
    )
  })
}

Blocks.propTypes = {
  postType: PropTypes.string,
  children: PropTypes.object,
  rest: PropTypes.object,
}
Blocks.defaultProps = {
  postType: '',
  children: {},
  rest: {},
}

export default Blocks
