import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import Slider from 'react-slick'

function SliderBlock({
  parentRef,
  setKey,
  settings,
  textColour,
  className,
  children,
}) {
  const defaultSettings = {
    dots: true,
    infinite: true,
    arrows: false,
    speed: 500,
    pauseOnHover: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    appendDots: (dots) => <ul>{dots}</ul>,
  }

  useEffect(() => {
    const dots = document.querySelectorAll('.slick-dots li')
    if (dots?.length > 0) {
      dots.forEach((e) => {
        e.style.backgroundColor = textColour
      })
    }
  })

  return (
    <Slider
      ref={parentRef}
      key={setKey}
      {...(settings ? { ...settings } : { ...defaultSettings })}
      className={className}
    >
      {children}
    </Slider>
  )
}

SliderBlock.propTypes = {
  parentRef: PropTypes.object,
  setKey: PropTypes.string,
  settings: PropTypes.object,
  textColour: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.node,
}
SliderBlock.defaultProps = {
  parentRef: {},
  setKey: null,
  settings: {},
  textColour: '',
  className: '',
  children: null,
}

export default SliderBlock
