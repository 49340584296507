import PropTypes from 'prop-types'
import classNames from 'classnames'

import CloudinaryMedia from 'components/CloudinaryMedia'

import s from './ImageVideoBlock.module.css'

function ImageVideoBlock({ mediaStyle, mediaType, mp4Video, image }) {
  const vidWidth = mp4Video?.mediaDetails?.width
  const vidHeight = mp4Video?.mediaDetails?.height
  const vidIsPortrait = vidHeight > vidWidth
  return (
    <div
      className={classNames(s.container, {
        [s.containerSlice]: mediaStyle === 'slice',
      })}
    >
      <CloudinaryMedia
        className={classNames(s.media, { [s.mediaPortrait]: vidIsPortrait })}
        videos={
          mediaType === 'video'
            ? [
                {
                  url: mp4Video?.mediaItemUrl,
                  // params: 'f_auto,vc_auto,ac_none'
                },
              ]
            : null
        }
        images={
          mediaType === 'image'
            ? [
                { url: image?.mediaItemUrl, params: 'w_899,f_auto' },
                { url: image?.mediaItemUrl, params: 'w_1112,f_auto' },
              ]
            : null
        }
      />
    </div>
  )
}

ImageVideoBlock.propTypes = {
  mediaStyle: PropTypes.string,
  mediaType: PropTypes.string,
  mp4Video: PropTypes.object,
  image: PropTypes.object,
}
ImageVideoBlock.defaultProps = {
  mediaStyle: '',
  mediaType: '',
  mp4Video: {},
  image: {},
}

export default ImageVideoBlock
