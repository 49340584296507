import { Fragment } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import CloudinaryMedia from 'components/CloudinaryMedia'

import s from './GalleryStackBlock.module.css'

function GalleryStackBlock({ gallery }) {
  if (gallery?.length < 1) return null

  const colClass = (style) => {
    if (style === 'half') {
      return s.half
    }
  }

  const mediaClass = (style, img) => {
    if (!img) return null
    const width = img.mediaDetails?.width
    const height = img.mediaDetails?.height
    if (width > height) {
      return s.mediaLandscape
    } else if (
      (style === 'full' && height > width) ||
      (style === 'full' && height === width)
    ) {
      return s.mediaHalf
    }
  }

  const media = (image, video, mediaStyle) => {
    const vidWidth = video?.mediaDetails?.width
    const vidHeight = video?.mediaDetails?.height
    const vidIsPortrait = vidHeight > vidWidth
    return (
      <div className={classNames(s.item, colClass(mediaStyle))}>
        <CloudinaryMedia
          className={classNames(s.media, mediaClass(mediaStyle, image), {
            [s.mediaPortrait]: vidIsPortrait,
          })}
          videos={[
            {
              url: video?.mediaItemUrl,
              // params: 'f_auto,vc_auto,ac_none'
            },
          ]}
          images={[
            { url: image?.mediaItemUrl, params: 'w_899,q_100,f_auto' },
            {
              url: image?.mediaItemUrl,
              params: 'w_1112,q_100,f_auto',
            },
          ]}
        />
      </div>
    )
  }

  return (
    <div className={s.container}>
      {gallery.map(({ videoMp4, image, image2, mediaStyle }) => {
        if (videoMp4 !== null || image !== null) {
          return (
            <Fragment key={`${videoMp4?.mediaItemUrl || image?.mediaItemUrl}`}>
              {media(image, videoMp4, mediaStyle)}
              {image2 &&
                mediaStyle === 'half' &&
                media(image2, null, mediaStyle)}
            </Fragment>
          )
        }
      })}
    </div>
  )
}

GalleryStackBlock.propTypes = {
  gallery: PropTypes.array,
}
GalleryStackBlock.defaultProps = {
  gallery: [],
}

export default GalleryStackBlock
