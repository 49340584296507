import { useState } from 'react'
import { useRouter } from 'next/router'
import PropTypes from 'prop-types'
import { Fade } from 'react-awesome-reveal'

import slugify from 'helpers/slugify'
import Link from 'components/Link'

import s from './TextSectionsBlock.module.css'

function TextSectionsBlock({ sections, activeSectionSlug = null }) {
  // todo: this block should no longer be used, it should have the related
  // blocks deleted in wordpress so this can be deleted
  if (sections?.length < 1) return null

  const router = useRouter()
  let initialActiveSection = activeSectionSlug
    ? sections?.findIndex(
        (section, i) =>
          slugify(section.subSections[0].menuLabel) === activeSectionSlug
      )
    : 0
  if (initialActiveSection < 0) initialActiveSection = 0

  const [activeSection, setActiveSection] = useState(initialActiveSection)

  return (
    <Fade cascade duration={500}>
      <div className={s.container}>
        <nav className={s.menu}>
          {sections?.map((section, i) =>
            section?.subSections?.map(({ menuLabel }) => (
              <button
                type="button"
                key={menuLabel}
                className={s.menuLink}
                onClick={() => {
                  window.location = `/${router.query.site}/${
                    router.query.slug[0]
                  }${i === 0 ? '' : `/${slugify(menuLabel)}`}`
                }}
              >
                {menuLabel}
              </button>
            ))
          )}
        </nav>
        <div className={s.content}>
          {sections[activeSection]?.subSections?.map(
            ({ menuTitle, content }, i) => (
              <div
                key={`${menuTitle}${i}`}
                id={menuTitle}
                dangerouslySetInnerHTML={{ __html: content }}
              />
            )
          )}
        </div>
      </div>
    </Fade>
  )
}

TextSectionsBlock.propTypes = {
  sections: PropTypes.array,
}
TextSectionsBlock.defaultProps = {
  sections: [],
}

export default TextSectionsBlock
