import { Fragment } from 'react'
import PropTypes from 'prop-types'
import Carousel from 'react-multi-carousel'

// NOTE: Importing doesnt work for some reason so manually added css.
// import 'react-multi-carousel/lib/styles.css';

import CloudinaryMedia from 'components/CloudinaryMedia'

import s from './SlideshowBlock.module.css'

function SlideshowBlock({ slideshow }) {
  if (slideshow?.length < 1) return null

  const responsive = {
    retina: {
      breakpoint: { max: 3000, min: 1920 },
      items: 6,
      partialVisibilityGutter: 80,
      draggable: false,
    },
    desktop: {
      breakpoint: { max: 1919, min: 1400 },
      items: 5,
      partialVisibilityGutter: 80,
      draggable: false,
    },
    laptop: {
      breakpoint: { max: 1399, min: 1024 },
      items: 4,
      partialVisibilityGutter: 60,
      draggable: false,
    },
    tabletLarge: {
      breakpoint: { max: 1023, min: 900 },
      items: 3,
      partialVisibilityGutter: 60,
      draggable: false,
    },
    tablet: {
      breakpoint: { max: 899, min: 600 },
      items: 2,
      partialVisibilityGutter: 60,
    },
    mobile: {
      breakpoint: { max: 599, min: 0 },
      items: 1,
      partialVisibilityGutter: 60,
    },
  }

  return (
    <Carousel
      containerClass={s.container}
      itemClass={s.image}
      responsive={responsive}
      arrows={false}
      infinite
      autoPlay={responsive !== 'mobile'}
      autoPlaySpeed={2000}
      partialVisible="right"
      minimumTouchDrag={80}
      ssr
    >
      {slideshow.map(({ mobileImage, desktopImage }) => (
        <Fragment key={mobileImage?.sourceUrl || desktopImage?.sourceUrl}>
          <CloudinaryMedia
            images={[
              {
                url: mobileImage?.sourceUrl,
                params: 'w_270,f_auto',
              },
              {
                url: desktopImage?.sourceUrl,
                params: 'w_215,f_auto',
              },
            ]}
          />
        </Fragment>
      ))}
    </Carousel>
  )
}

SlideshowBlock.defaultProps = {
  slideshow: [],
}

SlideshowBlock.propTypes = {
  slideshow: PropTypes.array.isRequired,
}

export default SlideshowBlock
